








import Vue from 'vue'
import FormField from './ConfirmAccountFormField.vue'

export default Vue.extend({
  props: {
    accountName: {
      type: String,
      required: true,
    },
    transitNumber: {
      type: String,
      required: true,
    },
    routingNumber: {
      type: String,
      required: true,
    },
    accountNumber: {
      type: String,
      required: true,
    },
  },
  components: {
    FormField,
  },
})
