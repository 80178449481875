<template>
  <div class="field">
    <label class="label">{{label}}</label>
    <div class="control has-icons-right">
      <input class="input is-medium-height" :value="value" readonly>
      <span class="icon is-small is-right">
        <img src="@assets/icons/check.svg" alt="is-success" style="width: 16px;">
      </span>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    label: {
      type: String,
      required: true
    },
    value: {
      type: String,
      required: true
    },
  },
}
</script>
<style lang="scss" scoped>
.field {
  .label {
    padding-left: 10px;
  }
  .control > input {
    border-color: #b5b5b5 !important;
  }
}
</style>
