<template>
  <div class="confirm-account-wrap">
    <NavBack/>
    <div class="fixed-background sticky-header">
      <MenuAndLogo></MenuAndLogo>
    </div>
    <div class="has-padding">
      <div class="page-title has-text-weight-semibold has-text-centered">Add new bank</div>
      <div class="main has-background-white">
        <div class="has-text-centered has-text-weight-semibold confirm">Confirm bank details</div>
        <div class="hint has-text-centered">Click “Next” if the following bank details for your account is correct.</div>
        <div class="form-fields">
          <Fields
            :accountName="accountName"
            :transitNumber="transitNumber"
            :routingNumber="routingNumber"
            :accountNumber="accountNumber"
          ></Fields>
          <div class="personal-account-field">
            <b-checkbox v-model="isPersonalAccount" class="personal-account" type="is-blue">This is a personal account (if it is a business account, please uncheck).</b-checkbox>
          </div>
        </div>
      </div>
      <BottomButtons :nextDisabled="false" :showSteps="false" @goNext="goNext" @goBack="goBack"></BottomButtons>
    </div>
  </div>
</template>
<script>
import NavBack from '@components/desktop/header-nav-back.vue'
import MenuAndLogo from '@views/index/MenuAndLogo.vue'
import Fields from './FormFields.vue'
import BottomButtons from '@components/button/bottom-buttons.vue'
import { getBankAccountInfoFromPlaid } from '@api/wallet'
import { encode } from 'js-base64'

export default {
  data() {
    return {
      accountName: '',
      transitNumber: '',
      routingNumber: '',
      accountNumber: '',
      isPersonalAccount: true,
      plaidAccountId: '',
    }
  },
  components: {
    NavBack,
    MenuAndLogo,
    Fields,
    BottomButtons,
  },
  created() {
    this.autoPopulate()
  },
  methods: {
    autoPopulate() {
      const { token, accountId, sessionId } = this.$route.query
      getBankAccountInfoFromPlaid({
        plaid_token: decodeURIComponent(token),
        plaid_account_id: decodeURIComponent(accountId),
        plaid_link_session_id: decodeURIComponent(sessionId),
      }).then((data) => {
        if (!data.success) {
          return this.$buefy.toast.open({
            message: 'Error...',
            duration: 3000,
            type: 'is-danger',
          })
        }
        const { accountName, transitNumber, routingNumber, accountNumber, plaidAccountId } = data.data.eftAccount
        this.accountName = accountName
        this.transitNumber = transitNumber
        this.routingNumber = routingNumber
        this.accountNumber = accountNumber
        this.plaidAccountId = plaidAccountId
      }).catch(() => {
        this.$store.commit('toggleLoadingMask', false)
        this.goBack()
      })
    },
    goNext() {
      const params = {
        type: 'PLAID',
        accountName: this.accountName,
        transitNumber: this.transitNumber,
        routingNumber: this.routingNumber,
        accountNumber: this.accountNumber,
        plaidAccountId: this.plaidAccountId,
        isPersonalAccount: this.isPersonalAccount,
      }
      this.$router.push({
        path: '/wallet/accounts/padAgreement',
        query: {
          params: encode(JSON.stringify(params)),
        },
      })
    },
    goBack() {
      this.$router.replace('/wallet/accounts/new')
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@assets/style/desktop-constants.scss';

.confirm-account-wrap {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  padding-bottom: 80px;
  min-height: 100vh;
  .has-padding {
    padding: 0 25px;
    .page-title {
      font-size: 32px;
      line-height: 32px;
      margin-bottom: 16px;
      letter-spacing: 0.03em;
    }
    .main {
      height: 550px;
      border-radius: 12px;
      box-shadow: 0px 20px 40px rgba(215, 145, 51, 0.15);
      margin-bottom: 30px;
      .confirm {
        font-size: 16px;
        padding-top: 24px;
      }
      .hint {
        padding: 8px 40px 33px;
        font-size: 16px;
        line-height: 19px;
      }
      .form-fields {
        padding: 0 13px;
        .personal-account-field {
          padding: 10px 10px 0;
        }
      }
    }
  }
}
@media only screen and (min-width: $min-viewport-width) {
  .confirm-account-wrap {
    padding-top: 19px;
    min-height: $min-height-mobile-container;
    max-width: $mobile-wrapper-width;
    position: unset;
  }
}
</style>
<style scoped>
.checkbox.personal-account >>> .check {
  width: 22px!important;
  height: 22px!important;
}
.checkbox.personal-account >>> .control-label {
  font-size: 16px;
  line-height: 19px;
  padding-left: 15px;
}
</style>
